import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import IO from "../../../components/pose/IO";
import { UpPose } from "../../../components/pose/Poses";
import color from "../../../common/styles/color";
import MEDIA from "../../../common/styles/media";

const Features = ({ data }) => (
  /*<IO>
    {({ isVisible, hasBeenVisible }) => (
      <Wrapper>
        <UpPose pose={isVisible || hasBeenVisible ? "visible" : "hidden"}>
          <Grid>
            {data.features.map((feature, i) => (
              <TextColumn key={i}>
                <Subtitle color={color[feature.color]}>
                  {feature.subtitle}
                </Subtitle>
                <Title>{feature.title}</Title>
                <Description>{feature.description}</Description>
              </TextColumn>
            ))}
          </Grid>
        </UpPose>
      </Wrapper>
    )}
  </IO>*/
  <Wrapper>
    <Grid>
      <TextColumn>

        <Subtitle>
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-12">
                <g id="Group-8">
                  <circle id="Oval" fill="#FFECB6" cx="24" cy="24" r="24"></circle>
                  <g id="SVG-Layer" transform="translate(24.298982, 24.375871) rotate(13.000000) translate(-24.298982, -24.375871) translate(4.798982, 4.875871)" fill="#F7B500" fill-rule="nonzero">
                    <path d="M0.069772442,18.7451454 L0.069772442,13.7763745 C0.069772442,13.5323711 0.266439173,13.3345674 0.50903987,13.3345674 C0.62554084,13.3345674 0.737270232,13.3811148 0.819648846,13.4639697 L7.22670976,19.9080731 C7.39825432,20.0806095 7.39825432,20.3603462 7.22670976,20.5328827 C7.14433112,20.6157376 7.03260173,20.6622849 6.91610076,20.6622849 L3.67419273,20.6622849 C4.54823702,28.6147245 11.2515867,34.8001109 19.3913064,34.8001109 C23.8668422,34.8001109 28.0387074,32.9227112 31.0152487,29.6788726 C31.6733869,28.9616328 32.7850088,28.916806 33.4981257,29.5787493 C34.2112426,30.2406924 34.2558117,31.3587412 33.5976735,32.0759811 C29.9626365,36.037449 24.8580932,38.3345674 19.3913064,38.3345674 C9.23101596,38.3345674 0.901814653,30.4494165 0.123023796,20.4315023 C0.0890668597,20.3687991 0.069772442,20.2969048 0.069772442,20.2204779 L0.069772442,19.3936226 C0.0656254676,19.2279379 0.063539615,19.0617423 0.063539615,18.8950567 C0.063539615,18.8445636 0.0656450501,18.7945652 0.069772442,18.7451454 Z" id="2"></path>
                    <path d="M38.1784846,19.6568913 L38.1784846,24.6256622 C38.1784846,24.8696655 37.9818179,25.0674692 37.7392171,25.0674692 C37.6227162,25.0674692 37.5109868,25.0209219 37.4286082,24.938067 L31.0215473,18.4939635 C30.8500027,18.3214271 30.8500027,18.0416904 31.0215473,17.869154 C31.1039259,17.7862991 31.2156553,17.7397517 31.3321563,17.7397517 L34.5740643,17.7397517 C33.70002,9.78731216 26.9966703,3.60192573 18.8569506,3.60192573 C14.3814148,3.60192573 10.2095497,5.47932546 7.23300831,8.72316401 C6.57487017,9.44040384 5.46324827,9.48523055 4.75013135,8.82328738 C4.03701443,8.16134412 3.99244535,7.0432954 4.65058353,6.32605558 C8.28562054,2.36458762 13.3901638,0.0674692424 18.8569506,0.0674692424 C29.0172411,0.0674692424 37.3464424,7.95262015 38.1252332,17.9705343 C38.1591902,18.0332375 38.1784846,18.1051319 38.1784846,18.1815588 L38.1784846,19.008414 C38.1826316,19.1740987 38.1847174,19.3402944 38.1847174,19.5069799 C38.1847174,19.557473 38.182612,19.6074714 38.1784846,19.6568913 Z" id="1"></path>
                  </g>
                  <rect id="Rectangle" fill="#F7B500" x="15.5" y="25.5" width="8" height="8" rx="1"></rect>
                  <rect id="Rectangle" fill="#F7B500" x="19.5" y="20.5" width="8" height="8" rx="1"></rect>
                  <rect id="Rectangle" fill="#F7B500" x="23.5" y="15.5" width="8" height="8" rx="1"></rect>
                </g>
              </g>
            </g>
          </svg></Subtitle>
        <Title>LEARN</Title>
        <Description>Videos, notes and flashacards to aid your learning. Hundreds of bespoke diagrams.</Description>
      </TextColumn>


      <TextColumn>
        <Subtitle>
          <svg width="47px" height="47px" viewBox="0 0 47 47" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-11" fill-rule="nonzero">
                <g id="Group-5">
                  <path d="M38.4528137,5.35992258 C44.9776851,10.7314492 48.1173073,19.1918616 46.6761533,27.5195456 C45.2349993,35.8472296 39.434861,42.7605645 31.484355,45.6270431 C23.533849,48.4935218 14.6565487,46.8719964 8.23281368,41.3799226 C-1.57124302,32.9977893 -2.79080128,18.2816001 5.49970026,8.3999368 C13.7902018,-1.48172652 28.4944001,-2.83822876 38.4528137,5.35992258 Z" id="Path" fill="#D1C7FF" transform="translate(23.510503, 23.510478) scale(1, -1) rotate(-180.000000) translate(-23.510503, -23.510478) "></path>
                  <g id="Group-4" transform="translate(4.300000, 5.000000)" fill="#8F70FF">
                    <path d="M20.2595869,0.488549248 C19.6457322,-0.0410011716 18.7768977,-0.150295347 18.0524028,0.210898632 C17.336103,0.576261747 16.9212885,1.35152506 17.0124795,2.15445295 C17.0973712,2.95536819 17.6597787,3.64949473 18.4344154,3.9057876 C19.2090522,4.15140161 20.0473577,3.9057876 20.5567079,3.29709202 C21.2464529,2.46414017 21.1191153,1.20403353 20.2595869,0.488549248 Z" id="Path" transform="translate(19.000000, 2.000000) scale(-1, 1) rotate(-180.000000) translate(-19.000000, -2.000000) "></path>
                    <path d="M37.2595869,17.4885492 C36.6457322,16.9589988 35.7768977,16.8497047 35.0524028,17.2108986 C34.336103,17.5762617 33.9212885,18.3515251 34.0124795,19.1544529 C34.0973712,19.9553682 34.6597787,20.6494947 35.4344154,20.9057876 C36.2090522,21.1514016 37.0473577,20.9057876 37.5567079,20.297092 C38.2464529,19.4641402 38.1191153,18.2040335 37.2595869,17.4885492 Z" id="Path" transform="translate(36.000000, 19.000000) scale(-1, 1) rotate(-180.000000) translate(-36.000000, -19.000000) "></path>
                    <path d="M3.25958692,17.4885492 C2.6457322,16.9589988 1.77689773,16.8497047 1.05240281,17.2108986 C0.336103018,17.5762617 -0.07871145,18.3515251 0.0124795287,19.1544529 C0.0973712253,19.9553682 0.659778715,20.6494947 1.43441545,20.9057876 C2.20905218,21.1514016 3.04735768,20.9057876 3.55670786,20.297092 C4.24645289,19.4641402 4.11911535,18.2040335 3.25958692,17.4885492 Z" id="Path" transform="translate(2.000000, 19.000000) scale(-1, 1) rotate(-180.000000) translate(-2.000000, -19.000000) "></path>
                    <path d="M34.2595869,9.48854925 C33.6457322,8.95899883 32.7768977,8.84970465 32.0524028,9.21089863 C31.336103,9.57626175 30.9212885,10.3515251 31.0124795,11.1544529 C31.0973712,11.9553682 31.6597787,12.6494947 32.4344154,12.9057876 C33.2090522,13.1514016 34.0473577,12.9057876 34.5567079,12.297092 C35.2464529,11.4641402 35.1191153,10.2040335 34.2595869,9.48854925 Z" id="Path" transform="translate(33.000000, 11.000000) scale(-1, 1) rotate(-180.000000) translate(-33.000000, -11.000000) "></path>
                    <path d="M28.2595869,3.48854925 C27.6457322,2.95899883 26.7768977,2.84970465 26.0524028,3.21089863 C25.336103,3.57626175 24.9212885,4.35152506 25.0124795,5.15445295 C25.0973712,5.95536819 25.6597787,6.64949473 26.4344154,6.9057876 C27.2090522,7.15140161 28.0473577,6.9057876 28.5567079,6.29709202 C29.2464529,5.46414017 29.1191153,4.20403353 28.2595869,3.48854925 Z" id="Path" transform="translate(27.000000, 5.000000) scale(-1, 1) rotate(-180.000000) translate(-27.000000, -5.000000) "></path>
                    <path d="M5.25958692,9.48854925 C4.6457322,8.95899883 3.77689773,8.84970465 3.05240281,9.21089863 C2.33610302,9.57626175 1.92128855,10.3515251 2.01247953,11.1544529 C2.09737123,11.9553682 2.65977871,12.6494947 3.43441545,12.9057876 C4.20905218,13.1514016 5.04735768,12.9057876 5.55670786,12.297092 C6.24645289,11.4641402 6.11911535,10.2040335 5.25958692,9.48854925 Z" id="Path" transform="translate(4.000000, 11.000000) rotate(-180.000000) translate(-4.000000, -11.000000) "></path>
                    <path d="M11.2595869,3.48854925 C10.6457322,2.95899883 9.77689773,2.84970465 9.05240281,3.21089863 C8.33610302,3.57626175 7.92128855,4.35152506 8.01247953,5.15445295 C8.09737123,5.95536819 8.65977871,6.64949473 9.43441545,6.9057876 C10.2090522,7.15140161 11.0473577,6.9057876 11.5567079,6.29709202 C12.2464529,5.46414017 12.1191153,4.20403353 11.2595869,3.48854925 Z" id="Path" transform="translate(10.000000, 5.000000) rotate(-180.000000) translate(-10.000000, -5.000000) "></path>
                    <path d="M34.2595869,24.4885492 C33.6457322,23.9589988 32.7768977,23.8497047 32.0524028,24.2108986 C31.336103,24.5762617 30.9212885,25.3515251 31.0124795,26.1544529 C31.0973712,26.9553682 31.6597787,27.6494947 32.4344154,27.9057876 C33.2090522,28.1514016 34.0473577,27.9057876 34.5567079,27.297092 C35.2464529,26.4641402 35.1191153,25.2040335 34.2595869,24.4885492 Z" id="Path" transform="translate(33.000000, 26.000000) scale(-1, -1) rotate(-180.000000) translate(-33.000000, -26.000000) "></path>
                    <path d="M28.2595869,31.4885492 C27.6457322,30.9589988 26.7768977,30.8497047 26.0524028,31.2108986 C25.336103,31.5762617 24.9212885,32.3515251 25.0124795,33.1544529 C25.0973712,33.9553682 25.6597787,34.6494947 26.4344154,34.9057876 C27.2090522,35.1514016 28.0473577,34.9057876 28.5567079,34.297092 C29.2464529,33.4641402 29.1191153,32.2040335 28.2595869,31.4885492 Z" id="Path" transform="translate(27.000000, 33.000000) scale(-1, -1) rotate(-180.000000) translate(-27.000000, -33.000000) "></path>
                    <path d="M5.25958692,24.4885492 C4.6457322,23.9589988 3.77689773,23.8497047 3.05240281,24.2108986 C2.33610302,24.5762617 1.92128855,25.3515251 2.01247953,26.1544529 C2.09737123,26.9553682 2.65977871,27.6494947 3.43441545,27.9057876 C4.20905218,28.1514016 5.04735768,27.9057876 5.55670786,27.297092 C6.24645289,26.4641402 6.11911535,25.2040335 5.25958692,24.4885492 Z" id="Path" transform="translate(4.000000, 26.000000) scale(1, -1) rotate(-180.000000) translate(-4.000000, -26.000000) "></path>
                    <path d="M11.2595869,31.4885492 C10.6457322,30.9589988 9.77689773,30.8497047 9.05240281,31.2108986 C8.33610302,31.5762617 7.92128855,32.3515251 8.01247953,33.1544529 C8.09737123,33.9553682 8.65977871,34.6494947 9.43441545,34.9057876 C10.2090522,35.1514016 11.0473577,34.9057876 11.5567079,34.297092 C12.2464529,33.4641402 12.1191153,32.2040335 11.2595869,31.4885492 Z" id="Path" transform="translate(10.000000, 33.000000) scale(1, -1) rotate(-180.000000) translate(-10.000000, -33.000000) "></path>
                    <path d="M20.2595869,34.4885492 C19.6457322,33.9589988 18.7768977,33.8497047 18.0524028,34.2108986 C17.336103,34.5762617 16.9212885,35.3515251 17.0124795,36.1544529 C17.0973712,36.9553682 17.6597787,37.6494947 18.4344154,37.9057876 C19.2090522,38.1514016 20.0473577,37.9057876 20.5567079,37.297092 C21.2464529,36.4641402 21.1191153,35.2040335 20.2595869,34.4885492 Z" id="Path" transform="translate(19.000000, 36.000000) scale(-1, 1) rotate(-180.000000) translate(-19.000000, -36.000000) "></path>
                  </g>
                  <path d="M24.9322246,14.08147 L23.8622246,20.67147 L28.5022246,20.87147 L28.6522246,20.87147 C29.0322246,20.94147 29.3522246,21.17147 29.5322246,21.51147 C29.7022246,21.86147 29.7022246,22.26147 29.5322246,22.61147 L23.7122246,33.77147 C23.5105544,34.1647685 23.0531423,34.3534198 22.6328503,34.2166384 C22.2125582,34.079857 21.9537902,33.6581292 22.0222246,33.22147 L23.1022246,26.62147 L18.4522246,26.40147 L18.3122246,26.40147 C17.9322246,26.33147 17.6122246,26.10147 17.4322246,25.76147 C17.2559252,25.4159952 17.2559252,25.0069448 17.4322246,24.66147 L23.2422246,13.52147 C23.433632,13.1087859 23.9028954,12.9038201 24.3357189,13.0438512 C24.7685424,13.1838823 25.028819,13.6248759 24.9422246,14.07147 L24.9322246,14.08147 Z" id="Path" fill="#6236FF" transform="translate(23.479862, 23.630474) scale(-1, -1) rotate(11.000000) translate(-23.479862, -23.630474) "></path>
                </g>
              </g>
            </g>
          </svg></Subtitle>
        <Title>QUIZ</Title>
        <Description>Hundreds of quizzes mapped to your course specification. On mobile or desktop - quick fire questions for active learning.</Description>
      </TextColumn>

      <TextColumn>
        <Subtitle>
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-10">
                <circle id="Oval" fill="#C8FBFF" cx="24" cy="24" r="24"></circle>
                <path d="M33.75,9 C34.9926406,9 36,10.0676287 36,11.3846154 L36,37.6153846 C36,38.9323713 34.9926406,40 33.75,40 L14.25,40 C13.0073593,40 12,38.9323713 12,37.6153846 L12,11.3846154 C12,10.0676287 13.0073593,9 14.25,9 L33.75,9 Z M29.875,36 L20.125,36 C19.5036797,36 19,36.4477153 19,37 C19,37.5522847 19.5036797,38 20.125,38 L29.875,38 C30.4963203,38 31,37.5522847 31,37 C31,36.4477153 30.4963203,36 29.875,36 Z M21,31 L17,31 C16.4477153,31 16,31.4477153 16,32 C16,32.5522847 16.4477153,33 17,33 L21,33 C21.5522847,33 22,32.5522847 22,32 C22,31.4477153 21.5522847,31 21,31 Z M32.9090909,28 L27.0909091,28 C26.4884167,28 26,28.4477153 26,29 C26,29.5522847 26.4884167,30 27.0909091,30 L32.9090909,30 C33.5115833,30 34,29.5522847 34,29 C34,28.4477153 33.5115833,28 32.9090909,28 Z M19.8333333,26 L15.1666667,26 C14.5223345,26 14,26.4477153 14,27 C14,27.5522847 14.5223345,28 15.1666667,28 L19.8333333,28 C20.4776655,28 21,27.5522847 21,27 C21,26.4477153 20.4776655,26 19.8333333,26 Z M27.0625,21 L18.9375,21 C18.4197331,21 18,21.4477153 18,22 C18,22.5522847 18.4197331,23 18.9375,23 L27.0625,23 C27.5802669,23 28,22.5522847 28,22 C28,21.4477153 27.5802669,21 27.0625,21 Z M31.875,16 L25.125,16 C24.5036797,16 24,16.4477153 24,17 C24,17.5522847 24.5036797,18 25.125,18 L31.875,18 C32.4963203,18 33,17.5522847 33,17 C33,16.4477153 32.4963203,16 31.875,16 Z M23.9285714,12 L16.0714286,12 C15.4796949,12 15,12.4477153 15,13 C15,13.5522847 15.4796949,14 16.0714286,14 L23.9285714,14 C24.5203051,14 25,13.5522847 25,13 C25,12.4477153 24.5203051,12 23.9285714,12 Z" id="Combined-Shape" fill="#348DE7" fill-rule="nonzero"></path>
                <path d="M40.2048,36.8100454 C40.0312426,36.9914745 39.791075,37.0940887 39.54,37.0940887 C39.288925,37.0940887 39.0487574,36.9914745 38.8752,36.8100454 C38.5089758,36.4266474 38.5089758,35.8230434 38.8752,35.4396454 L40.3776,33.8900454 L27.2,33.8900454 C26.5372583,33.8900454 26,33.3527871 26,32.6900454 C26,32.0273037 26.5372583,31.4900454 27.2,31.4900454 L40.3632,31.4900454 L38.8752,29.9556454 C38.5089758,29.5722474 38.5089758,28.9686434 38.8752,28.5852454 C39.0484298,28.4035028 39.2884121,28.3004547 39.5394873,28.2999869 C39.7905625,28.2995483 40.0309153,28.4017293 40.2048,28.5828454 L43.5288,32.0108454 C43.8968,32.3892454 43.8968,33.0028454 43.5288,33.3812454 L40.2048,36.8100454 Z M17.2030505,18.6024433 L7.43985046,18.6024433 L8.92785046,20.1368433 C9.29407469,20.5202413 9.29407469,21.1238453 8.92785046,21.5072433 C8.75429301,21.6886723 8.51412549,21.7912866 8.26305046,21.7912866 C8.01197543,21.7912866 7.77180792,21.6886723 7.59825046,21.5072433 L4.27505046,18.0824433 C3.90831651,17.6989402 3.90831651,17.0947464 4.27505046,16.7112433 L7.59825046,13.2840433 C7.77180792,13.1026143 8.01197543,13 8.26305046,13 C8.51412549,13 8.75429301,13.1026143 8.92785046,13.2840433 C9.29407469,13.6674412 9.29407469,14.2710453 8.92785046,14.6544433 L7.42545046,16.2024433 L17.2030505,16.2024433 C17.8657921,16.2024433 18.4030505,16.7397016 18.4030505,17.4024433 C18.4030505,18.065185 17.8657921,18.6024433 17.2030505,18.6024433 Z" id="1" fill="#0DF0E3" fill-rule="nonzero"></path>
              </g>
            </g>
          </svg></Subtitle>
        <Title>PRACTICE</Title>
        <Description>Past papers and exam style questions to test your understanding.</Description>
      </TextColumn>


      <TextColumn>
        <Subtitle>
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" >

            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-9">
                <g id="Group-6">
                  <circle id="Oval" fill="#FFD1F1" cx="24" cy="24" r="24"></circle>
                  <g id="SVG-Layer" transform="translate(23.200000, 24.000000) scale(-1, 1) translate(-23.200000, -24.000000) translate(7.200000, 8.000000)" fill-rule="nonzero">
                    <path d="M28.8,31.2 L2.4,31.2 C1.0745166,31.2 0,30.0603612 0,28.6545455 L0,5.74545455 C0,4.33963882 1.0745166,3.2 2.4,3.2 L28.8,3.2 C30.1254834,3.2 31.2,4.33963882 31.2,5.74545455 L31.2,28.6545455 C31.2,30.0603612 30.1254834,31.2 28.8,31.2 Z M8,12.4631579 C8,11.7655351 7.4627417,11.2 6.8,11.2 L5.2,11.2 C4.5372583,11.2 4,11.7655351 4,12.4631579 L4,25.9368421 C4,26.6344649 4.5372583,27.2 5.2,27.2 L6.8,27.2 C7.4627417,27.2 8,26.6344649 8,25.9368421 L8,12.4631579 Z M20.8,17.2923077 C20.8,16.5785859 20.2627417,16 19.6,16 L18,16 C17.3372583,16 16.8,16.5785859 16.8,17.2923077 L16.8,25.9076923 C16.8,26.6214141 17.3372583,27.2 18,27.2 L19.6,27.2 C20.2627417,27.2 20.8,26.6214141 20.8,25.9076923 L20.8,17.2923077 L20.8,17.2923077 Z M27.2,10.0545455 C27.2,9.36167913 26.6627417,8.8 26,8.8 L24.4,8.8 C23.7372583,8.8 23.2,9.36167913 23.2,10.0545455 L23.2,25.9454545 C23.2,26.6383209 23.7372583,27.2 24.4,27.2 L26,27.2 C26.6627417,27.2 27.2,26.6383209 27.2,25.9454545 L27.2,10.0545455 Z" id="Shape" fill="#F096D4"></path>
                    <path d="M14.5,2 L14.5,26 C14.5,27.1045695 13.6045695,28 12.5,28 C11.3954305,28 10.5,27.1045695 10.5,26 L10.5,2 C10.5,0.8954305 11.3954305,2.02906125e-16 12.5,0 C13.6045695,-2.02906125e-16 14.5,0.8954305 14.5,2 Z" id="Path" fill="#F3508D"></path>
                  </g>
                  <path d="M35.2,20.4 L35.2,34 C35.2,35.1045695 34.3045695,36 33.2,36 C32.0954305,36 31.2,35.1045695 31.2,34 L31.2,20.4 C31.2,19.2954305 32.0954305,18.4 33.2,18.4 C34.3045695,18.4 35.2,19.2954305 35.2,20.4 Z" id="Path" fill="#FFD1F1" fill-rule="nonzero"></path>
                  <path d="M22.4,25.2 L22.4,34 C22.4,35.1045695 21.5045695,36 20.4,36 C19.2954305,36 18.4,35.1045695 18.4,34 L18.4,25.2 C18.4,24.0954305 19.2954305,23.2 20.4,23.2 C21.5045695,23.2 22.4,24.0954305 22.4,25.2 Z" id="Path" fill="#FFD1F1" fill-rule="nonzero"></path>
                  <path d="M16,18 L16,34 C16,35.1045695 15.1045695,36 14,36 C12.8954305,36 12,35.1045695 12,34 L12,18 C12,16.8954305 12.8954305,16 14,16 C15.1045695,16 16,16.8954305 16,18 Z" id="Path" fill="#FFD1F1" fill-rule="nonzero"></path>
                </g>
              </g>
            </g>
          </svg></Subtitle>
        <Title>TRACK</Title>
        <Description>Track your progress with tailored feedback and insights.</Description>
      </TextColumn>

    </Grid>
  </Wrapper>
);

Features.propTypes = {
  data: PropTypes.object.isRequired
};

const FeaturesWithQuery = props => (
  <StaticQuery
    query={graphql`
      query SchoolsFeatureQuery {
        schoolsYaml {
          features {
            subtitle
            color
            title
            description
          }
        }
      }
    `}
    render={({ schoolsYaml }) => <Features data={schoolsYaml} {...props} />}
  />
);

export default FeaturesWithQuery;

/* STYLES 
Wrapper | Grid | TextColumn | ImageColumnn
Subtitle | Title | Description
ImageWrapper | ImageContainer
Action
*/

export const Wrapper = styled.div`
  background: ${color.greyBackground};
  display: flex;
  justify-content: center;
  padding: 40px 0;
  overflow: hidden;
  z-index: 100;
`;

export const Grid = styled.div`
  max-width: 2000px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${MEDIA.TABLET`
    grid-template-columns: 1fr 1fr;
  `};
  grid-gap: 48px;
  margin-left: 20px;
  margin-right: 20px;
  ${MEDIA.TABLET`
    margin: 0vw 10vw;
  `};
  ${MEDIA.MOBILE`
    margin: 0vw 10vw;
  `};
`;

export const TextColumn = styled.div`
  align-items: left;
  justify-content: top;
  display: flex;
  flex-flow: column;
`;

export const Subtitle = styled.h2`
  color: ${props => props.color};
  margin: 10px 0;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.4px;
  svg {
    margin-right: 3px;
  }
`;

export const Title = styled.h3`
  max-width: 380px;
  margin: 0px 0 10px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -1.2px;
  ${MEDIA.DESKTOP`
    font-size: 20px;
    letter-spacing: -1.1px;
  `};
  ${MEDIA.MOBILE`
    font-size: 28px;
    letter-spacing: -1px;
  `};
`;

export const Description = styled.p`
  max-width: 300px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.5px;
  ${MEDIA.DESKTOP`
    font-size: 18px;
  `};
  ${MEDIA.MOBILE`
    font-size: 17px;
  `};
`;

/*
const Features = ({ data }) => (
  <IO>
    {({ isVisible, hasBeenVisible }) => (
      <Wrapper>
        <UpPose pose={isVisible || hasBeenVisible ? "visible" : "hidden"}>
          <Grid>
            {data.features.map((feature, i) => (
              <TextColumn key={i}>
                <Subtitle color={color[feature.color]}>
                  {feature.subtitle}
                </Subtitle>
                <Title>{feature.title}</Title>
                <Description>{feature.description}</Description>
              </TextColumn>
            ))}
          </Grid>
        </UpPose>
      </Wrapper>
    )}
  </IO>
);

Features.propTypes = {
  data: PropTypes.object.isRequired
};

const FeaturesWithQuery = props => (
  <StaticQuery
    query={graphql`
      query SchoolsFeatureQuery {
        schoolsYaml {
          features {
            subtitle
            color
            title
            description
          }
        }
      }
    `}
    render={({ schoolsYaml }) => <Features data={schoolsYaml} {...props} />}
  />
);

export default FeaturesWithQuery;*/

/* STYLES
Wrapper | Grid | TextColumn | ImageColumnn
Subtitle | Title | Description
ImageWrapper | ImageContainer
Action
*/

/*export const Wrapper = styled.div`
  background: ${color.greyBackground};
  display: flex;
  justify-content: center;
  padding: 40px 0;
  overflow: hidden;
  z-index: 100;
`;

export const Grid = styled.div`
  max-width: 1600px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${MEDIA.TABLET`
    grid-template-columns: 1fr 1fr;
  `};
  grid-gap: 48px;
  margin-left: 20px;
  margin-right: 20px;
  ${MEDIA.TABLET`
    margin: 0vw 10vw;
  `};
  ${MEDIA.MOBILE`
    margin: 0vw 10vw;
  `};
`;

export const TextColumn = styled.div`
  align-items: left;
  justify-content: top;
  display: flex;
  flex-flow: column;
`;

export const Subtitle = styled.h2`
  color: ${props => props.color};
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
  svg {
    margin-right: 3px;
  }
`;

export const Title = styled.h3`
  max-width: 380px;
  margin: 0px 0 10px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -1.2px;
  ${MEDIA.DESKTOP`
    font-size: 20px;
    letter-spacing: -1.1px;
  `};
  ${MEDIA.MOBILE`
    font-size: 28px;
    letter-spacing: -1px;
  `};
`;

export const Description = styled.p`
  max-width: 200px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.5px;
  ${MEDIA.DESKTOP`
    font-size: 18px;
  `};
  ${MEDIA.MOBILE`
    font-size: 17px;
  `};
`;
*/